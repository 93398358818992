import { handleActions } from 'redux-actions'
import fetch from '../services/fetch'
import logger from '../services/errorlogger'
import history from '../history'
import { saveAs } from 'file-saver'
import { message } from 'antd'
import URI from 'urijs'

export const actionTypes = {
  FETCH_ORDERS_LOADING: 'FETCH_ORDERS_LOADING',
  FETCH_ORDERS_ERROR: 'FETCH_ORDERS_ERROR',
  FETCH_ORDERS_SUCCESS: 'FETCH_ORDERS_SUCCESS',
  CREATE_ORDER_LOADING: 'CREATE_ORDER_LOADING',
  CREATE_ORDER_SUCCESS: 'CREATE_ORDER_SUCCESS',
  FLUSH_ORDERS: 'FLUSH_ORDERS',

  UPDATE_ORDER_LOADING: 'UPDATE_ORDER_LOADING',
  UPDATE_ORDER_ERROR: 'UPDATE_ORDER_ERROR',
  UPDATE_ORDER_SUCCESS: 'UPDATE_ORDER_SUCCESS',
}

export const actions = {
  flushOrders: () => async (dispatch, getState) => dispatch({ type: actionTypes.FLUSH_ORDERS }),
  fetchOrders:
    ({ search, dateFrom, dateTo, withDelta, withCounterpart }) =>
    async (dispatch, getState) => {
      dispatch({ type: actionTypes.FETCH_ORDERS_LOADING })
      try {
        const params = new URLSearchParams()
        if (search) {
          params.append('search', search)
        }
        if (dateFrom) {
          params.append('dateFrom', dateFrom)
        }
        if (dateTo) {
          params.append('dateTo', dateTo)
        }
        if (withDelta) {
          params.append('withDelta', withDelta)
        }
        if (withCounterpart) {
          params.append('withCounterpart', withCounterpart)
        }

        const { data: orders } = await fetch.get(`orders?${params}`)
        dispatch({ type: actionTypes.FETCH_ORDERS_SUCCESS, orders })
      } catch (e) {
        dispatch({ type: actionTypes.FETCH_ORDERS_ERROR })
        logger.handleError(e)
      }
    },
  fetchOrder: (orderId) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.FETCH_ORDERS_LOADING })
    try {
      const { data: order } = await fetch.get(`order/${orderId}`)
      dispatch({ type: actionTypes.FETCH_ORDERS_SUCCESS, orders: [order] })
    } catch (e) {
      logger.handleError(e)
    }
  },
  removeOrder: (orderId) => async (dispatch, getState) => {
    console.log('removing', orderId)
    if (!orderId) return
    try {
      dispatch({ type: actionTypes.FETCH_ORDERS_LOADING })
      console.log(orderId)
      await fetch.delete(`orders/${orderId}`)
      dispatch(actions.fetchOrders())
    } catch (e) {
      logger.handleError(e)
    }
  },
  createOrder: (props) => async (dispatch, getState) => {
    if (!props) return
    try {
      await fetch.put(`orders`, props)
      history.push('/app/orders/')
    } catch (e) {
      logger.handleError(e)
    }
  },
  generateOrder: (props, data) => async (dispatch, getState) => {
    if (!props) return
    try {
      const { data: url } = await fetch.post(`orders/orderId`, { props, data })
      // deb
      // debugger;
      message.info(url)
      // saveAs(url);
      console.log('Document created successfully')
      // history.push('/app/orders/');
    } catch (e) {
      logger.handleError(e)
    }
  },
  updateOrder: (props) => async (dispatch, getState) => {
    if (!props) return
    try {
      await fetch.patch(`orders`, props)
      dispatch(actions.fetchOrders())
    } catch (e) {
      logger.handleError(e)
    }
  },
  setOrderSmsStatus: (orderId, status) => async (dispatch, getState) => {
    if (!orderId || !status) return
    try {
      dispatch({ type: actionTypes.UPDATE_ORDER_LOADING })
      const orders = getState().orders.orders
      const order = orders.find((o) => o.id === orderId)
      if (!order) throw new Error('Order not found')

      const setStatusValue = (description) => {
        if (!description || !description.length) {
          return 'Отправлено СМС с ТТН: Да'
        }
        if (!description.includes('Отправлено СМС с ТТН')) {
          return description.concat('\n').concat(`Отправлено СМС с ТТН: ${status ? 'Да' : 'Нет'}`)
        }
        const splitD = order.description.split('\n')
        const result = splitD
          .map((d) => {
            if (d.includes('Отправлено СМС с ТТН')) {
              return `Отправлено СМС с ТТН: ${status ? 'Да' : 'Нет'}`
            }
            return d
          })
          .join('\n')
        return result
      }
      let description = setStatusValue(order.description)

      await fetch.patch(`orders/${orderId}`, { description })

      dispatch({ type: actionTypes.UPDATE_ORDER_SUCCESS })
    } catch (e) {
      dispatch({ type: actionTypes.UPDATE_ORDER_ERROR })
      logger.handleError(e)
    }
  },
}

export const getDefaultState = () => ({
  ordersLoading: false,
  ordersError: false,

  orderUpdateLoading: false,
  orderUpdateError: false,
  orderUpdateSuccess: false,

  orders: [],
})

export default handleActions(
  {
    [actionTypes.FLUSH_ORDERS]: () => ({ ...getDefaultState() }),
    [actionTypes.FETCH_ORDERS_LOADING]: (state) => ({
      ...state,
      ordersLoading: true,
      ordersError: false,
    }),
    [actionTypes.FETCH_ORDERS_SUCCESS]: (state, { orders }) => {
      return { ...state, orders, ordersLoading: false, ordersError: false }
    },
    [actionTypes.FETCH_ORDERS_ERROR]: (state) => ({
      ...state,
      ordersLoading: false,
      ordersError: true,
    }),
    [actionTypes.UPDATE_ORDER_LOADING]: (state) => ({
      ...state,
      orderUpdateLoading: true,
      orderUpdateError: false,
      orderUpdateSuccess: false,
    }),
    [actionTypes.UPDATE_ORDER_SUCCESS]: (state) => ({
      ...state,
      orderUpdateLoading: false,
      orderUpdateError: false,
      orderUpdateSuccess: true,
    }),
    [actionTypes.UPDATE_ORDER_ERROR]: (state) => ({
      ...state,
      orderUpdateLoading: false,
      orderUpdateError: true,
      orderUpdateSuccess: false,
    }),
  },
  getDefaultState()
)
