import React, { Component, Fragment } from 'react'
import { Button, Input, Typography, Statistic, message, Tag, List, DatePicker } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import history from '../../history'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'

import { actions as OrdersActions } from '../../store/OrdersReducer'
import { actions as SMSActions } from '../../store/SMSReducer'
import OrdersDeltaTable from './OrdersDeltaTable'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

const { Title } = Typography

const calcPositions = (positions) => {
  return positions.map((p) => {
    const priceSale = p.price
    const priceDzen = p.assortment.salePrices.find(
      (price) => price.priceType.name === 'Цена Дзен'
    ).value
    const pricePochatkova = p.assortment.salePrices.find(
      (price) => price.priceType.name === 'Початкова'
    ).value

    return {
      priceSale,
      priceDzen,
      pricePochatkova,
      diffAbs: priceDzen - pricePochatkova,
      diffPerc: (priceDzen - pricePochatkova) / pricePochatkova,
      quantity: p.quantity,
      totalPochatkova: pricePochatkova * p.quantity,
      priceSale: priceSale * p.quantity,
    }
  })
}

const transformOrder = (order) => {
  const positions = order.positions.full
  const calcedPositions = calcPositions(positions)

  const totalPochatkova = calcedPositions.reduce((prev, curr) => prev + curr.totalPochatkova, 0)
  const totalSale = calcedPositions.reduce((prev, curr) => prev + curr.priceSale, 0)
  const profitAbs = totalSale - totalPochatkova
  const profitPerc = profitAbs / totalSale

  const profit = {
    totalCost: totalPochatkova,
    totalSale: totalSale,
    profitAbs: profitAbs,
    profitPerc: profitPerc,
  }

  const isCompleted =
    order.state.meta.href ===
    'https://api.moysklad.ru/api/remap/1.2/entity/customerorder/metadata/states/3910b662-a2a3-11ea-0a80-030c000c593f'

  const isConfirmed = order.applicable

  return {
    ...order,
    profit,
    isCompleted,
    isConfirmed,
  }
}

class OrderContainer extends Component {
  constructor(props) {
    super(props)
    const currentDate = dayjs() // Get current date with dayjs
    const dateFrom = currentDate.startOf('month') // Set to the first day of the current month

    this.state = {
      dateFrom: dateFrom, // Convert dayjs object to Date for consistency
      dateTo: currentDate, // Current date as Date object
      search: 'DZEN', // Search term initialized as undefined
    }
  }

  componentDidMount() {
    document.title = 'Маржа Дзен | Liliya'
  }

  onSearch = () => {
    console.log('fetching')
    const { dateFrom, dateTo, search, selectedOrderId } = this.state
    this.props.fetchOrders({ search, dateFrom, dateTo, withDelta: true })
  }

  getStats = (orders) => {
    if (!orders || orders.length === 0) return {}

    const createStats = (os) => {
      const orders = os.length
      const revenue = Math.floor(os.reduce((acc, order) => acc + Number(order.sum), 0)) / 100
      const aov = Math.floor(revenue / orders)
      const cogs =
        Math.floor(os.reduce((acc, order) => acc + Number(order.profit.totalCost), 0)) / 100
      const profit =
        Math.floor(os.reduce((acc, order) => acc + Number(order.profit.profitAbs), 0)) / 100
      const avgProfit = Math.floor(profit / orders)
      const margin = Math.floor((profit / revenue) * 10000) / 100

      return { orders, revenue, aov, cogs, profit, avgProfit, margin }
    }

    const all = createStats(orders)
    const confirmed = createStats(orders.filter((o) => o.isConfirmed))
    const completed = createStats(orders.filter((o) => o.isCompleted))

    return { all, confirmed, completed }
  }

  render() {
    const { dateFrom, dateTo, search, selectedOrderId } = this.state
    const { orders, ordersLoading } = this.props.orders
    const mappedData = orders.map((d) => transformOrder(d))
    const stats = this.getStats(mappedData)
    // const selectedOrder = orders.find(o => o.id === selectedOrderId);
    return (
      <div>
        <div>
          <Title level={3} style={{ marginBottom: 5, marginTop: 0 }}>
            Замовлення дельта
          </Title>
        </div>
        <div style={{ display: 'flex', gap: 20, margin: '10px 0' }}>
          <Button onClick={() => this.onSearch()} loading={ordersLoading} type="primary">
            Search
          </Button>
          <div>
            from: <DatePicker value={dateFrom} onChange={(v) => this.setState({ dateFrom: v })} />
          </div>
          <div>
            to: <DatePicker value={dateTo} onChange={(v) => this.setState({ dateTo: v })} />
          </div>
          <div style={{ width: 200, display: 'flex', gap: 5, alignItems: 'center' }}>
            search:
            <Input value={search} onChange={(e) => this.setState({ search: e.target.value })} />
          </div>
        </div>
        {/* // {(orders, revenue, aov, cogs, profit, avgProfit, margin)} */}
        {stats.all && (
          <div style={{ display: 'flex', gap: 30, margin: '10px 0' }}>
            <div style={{ width: 100 }}>Всі:</div>
            <Statistic title="Orders" value={stats.all.orders} />
            <Statistic title="Revenue" value={stats.all.revenue} />
            <Statistic title="Cost of goods" value={stats.all.cogs} />
            <Statistic title="Profit" value={stats.all.profit} />
            <Statistic title="AOV" value={stats.all.aov} />
            <Statistic title="Average profit" value={stats.all.avgProfit} />
            <Statistic title="Margin %" value={stats.all.margin} />
          </div>
        )}
        {stats.confirmed && (
          <div style={{ display: 'flex', gap: 30, margin: '10px 0' }}>
            <div style={{ width: 100 }}>Підтверджені:</div>
            <Statistic title="Orders" value={stats.confirmed.orders} />
            <Statistic title="Revenue" value={stats.confirmed.revenue} />
            <Statistic title="Cost of goods" value={stats.confirmed.cogs} />
            <Statistic title="Profit" value={stats.confirmed.profit} />
            <Statistic title="AOV" value={stats.confirmed.aov} />
            <Statistic title="Average profit" value={stats.confirmed.avgProfit} />
            <Statistic title="Margin %" value={stats.confirmed.margin} />
          </div>
        )}
        {stats.completed && (
          <div style={{ display: 'flex', gap: 30, margin: '10px 0' }}>
            <div style={{ width: 100 }}>Виконані:</div>
            <Statistic title="Orders" value={stats.completed.orders} />
            <Statistic title="Revenue" value={stats.completed.revenue} />
            <Statistic title="Cost of goods" value={stats.completed.cogs} />
            <Statistic title="Profit" value={stats.completed.profit} />
            <Statistic title="AOV" value={stats.completed.aov} />
            <Statistic title="Average profit" value={stats.completed.avgProfit} />
            <Statistic title="Margin %" value={stats.completed.margin} />
          </div>
        )}
        <OrdersDeltaTable data={mappedData} isLoading={ordersLoading} />
      </div>
    )
  }
}

const mapStateToProps = (state) => state

const mapDispatchToProps = {
  ...OrdersActions,
  ...SMSActions,
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(OrderContainer)
